import React, { useState, useEffect } from 'react';
import './sudoku-style.css';

function generateEmptyGrid() {
  const grid = [];
  for (let i = 0; i < 9; i++) {
    const row = [];
    for (let j = 0; j < 9; j++) {
      row[j] = 0;
    }
    grid[i] = row;
  }
  return grid;
}

// Sudoku puzzle generation function
function generateSudoku() {
  const sudokuGrid = generateEmptyGrid();

  // Fill diagonal blocks
  for (let i = 0; i < 9; i += 3) {
    fillBlock(sudokuGrid, i, i);
  }

  // Solve complete Sudoku
  solveSudoku(sudokuGrid);

  // Remove some numbers to create puzzle
  let count = 30; // Adjust difficulty by changing count
  while (count > 0) {
    const row = Math.floor(Math.random() * 9);
    const col = Math.floor(Math.random() * 9);
    if (sudokuGrid[row][col] !== 0) {
      sudokuGrid[row][col] = 0;
      count--;
    }
  }

  return sudokuGrid;
}

// Fill a 3x3 block
function fillBlock(sudoku, row, col) {
  const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  shuffleArray(nums);
  let index = 0;
  for (let i = row; i < row + 3; i++) {
    for (let j = col; j < col + 3; j++) {
      sudoku[i][j] = nums[index++];
    }
  }
}

// Shuffle array function
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

// Sudoku solver function (Backtracking)
function solveSudoku(sudoku) {
  const emptyCell = findEmptyCell(sudoku);
  if (!emptyCell) {
    return true; // Puzzle complete
  }

  const [row, col] = emptyCell;
  for (let num = 1; num <= 9; num++) {
    if (isValid(sudoku, row, col, num)) {
      sudoku[row][col] = num;

      if (solveSudoku(sudoku)) {
        return true;
      }

      sudoku[row][col] = 0; // Backtrack
    }
  }

  return false; // No solution found
}

// Sudoku solver function (complete)
function getSolvedGrid(sudokuGrid) {
    const solvedSudokuGrid = [...sudokuGrid];
    const emptySpot = findEmptyCell(solvedSudokuGrid);
    const stack = []; // Stack to keep track of previous positions and numbers tried
  
    if (emptySpot != null)
    {
    let row = emptySpot[0];
    let col = emptySpot[1];
    let num = 1;
  
    while (emptySpot) {
      if (num <= 9) {
        if (isValid(solvedSudokuGrid, row, col, num)) {
          solvedSudokuGrid[row][col] = num;
          stack.push({ row, col, num });
          const nextEmptySpot = findEmptyCell(solvedSudokuGrid);
          if (nextEmptySpot) {
            row = nextEmptySpot[0];
            col = nextEmptySpot[1];
            num = 1;
          } else {
            return solvedSudokuGrid; // Puzzle solved
          }
        } else {
          num++;
        }
      } else {
        // Backtrack
        solvedSudokuGrid[row][col] = 0;
        const prevMove = stack.pop();
        if (!prevMove) {
          return sudokuGrid; // No solution found
        }
        row = prevMove.row;
        col = prevMove.col;
        num = prevMove.num + 1;
      }
    }
  }
  
    return sudokuGrid; // No solution found
  }
  

// Find empty cell in Sudoku grid
function findEmptyCell(sudoku) {
  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      if (sudoku[row][col] === 0) {
        return [row, col];
      }
    }
  }
  return null;
}

// Check if a number is valid in a cell
function isValid(sudoku, row, col, num) {
  // Check row and column
  for (let i = 0; i < 9; i++) {
    if (sudoku[row][i] === num || sudoku[i][col] === num) {
      return false;
    }
  }

  // Check 3x3 block
  const startRow = Math.floor(row / 3) * 3;
  const startCol = Math.floor(col / 3) * 3;
  for (let i = startRow; i < startRow + 3; i++) {
    for (let j = startCol; j < startCol + 3; j++) {
      if (sudoku[i][j] === num) {
        return false;
      }
    }
  }

  return true;
}

function copyGrid(gridToCopy)
{
  const newGridCopy = generateEmptyGrid();

  for (let row = 0; row < 9; row++) {
    for (let col = 0; col < 9; col++) {
      newGridCopy[row][col] = gridToCopy[row][col];
    }
  }

  return newGridCopy;
}

const SudokuGame = () => {
  const [initialGrid, setInitialGrid] = useState(generateEmptyGrid);  
  const [sudoku, setSudoku] = useState(generateEmptyGrid);
  const [solvedGrid, setSolvedGrid] = useState(generateEmptyGrid);

  useEffect(() => {
    const newGrid = generateSudoku();
    setInitialGrid(newGrid);
    setSudoku(copyGrid([...newGrid]));
    setSolvedGrid(getSolvedGrid(copyGrid([...newGrid])));
  }, []);
  
  const handleChange = (row, col, event) => {
    const value = event.target.value === '' ? 0 : parseInt(event.target.value);
       if (!isNaN(value) && value >= 0 && value <= 9) {
      const updatedSudoku = [...sudoku];
      updatedSudoku[row][col] = value;
      setSudoku(updatedSudoku);
    }
  };

  const resetGame = () => {
    const newGrid = generateSudoku();
    setInitialGrid(newGrid);
    setSudoku(copyGrid([...newGrid]));
    setSolvedGrid(getSolvedGrid(copyGrid([...newGrid])));
  }

  const clearBoard = () => {
    setSudoku(copyGrid([...initialGrid]));
  }

  const checkSolution = () => {
    let isValidSolution = true;

    const emptyCell = findEmptyCell(sudoku);
    if (emptyCell != null && emptyCell.length > 0)
    {
      alert('Please conplete the entire grid.')
    }
    else
    {
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        if (sudoku[i][j] !== solvedGrid[i][j]) {
          isValidSolution = false;
          break;
        }
      }
      if (!isValidSolution) break;
    }

    if (isValidSolution) {
      alert('Congratulations! You solved the Sudoku puzzle!');
    } else {
      alert('Sorry! Your solution is incorrect. Please try again.');
    }
  }
  };

  return (
<div>
<div className="App">
      <h1>Sudoku</h1>
      <table className="sudoku-grid">
        <tbody>
          {sudoku.map((row, i) => (
            <tr key={i} className={(i === 0 || i === 3 || i === 6) ? "sudoku-row-box-top" : i === 8 ? "sudoku-row-box-bottom" : "sudoku-row"}> 
              {row.map((col, j) => (
                <td key={j}>
                  <input
                    type="number"
                    className={"sudoku-cell" + (j === 0 || j === 3 || j === 6) ? " sudoku-cell-box-left" : j === 8 ? " sudoku-cell-box-right" : ""}
                    value={col === 0 ? '' : col}
                    readOnly={initialGrid[i][j] !== 0}
                    onChange={(e) => handleChange(i, j, e)}
                    tabIndex={initialGrid[i][j] !== 0 ? -1 : i * 10 + j + 1}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='navigationButtons'>
      <button onClick={resetGame} tabIndex="101">New</button> <button onClick={clearBoard} tabIndex="102">Clear</button> <button onClick={checkSolution} tabIndex="103">Check Solution</button>
      </div>
    </div>
  </div>
  );
};

export default SudokuGame;
