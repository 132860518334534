import React, { useState } from 'react';
import './coin-style.css';

const CoinFlip = () => {
  const [coinResult, setCoinResult] = useState(null);
  const [headsCount, setHeadsCount] = useState(0);
  const [tailsCount, setTailsCount] = useState(0);

  const flipCoin = () => {
    const randomResult = Math.random() < 0.5 ? 'HEADS' : 'TAILS';
    setCoinResult(randomResult);
    if (randomResult === 'HEADS')
        setHeadsCount(headsCount + 1);
    else if (randomResult === 'TAILS')
        setTailsCount(tailsCount + 1);
  };

  return (
    <div className="coin-flip">
      <h2>Coin Flip</h2>
      <button className='coin-button' onClick={flipCoin}>Flip Coin</button>
      {coinResult && (
        <p>Result of flip #{headsCount + tailsCount}: <strong>{coinResult}</strong></p>
      )}
      <p>Heads total: {headsCount}</p>
      <p>Tails total: {tailsCount}</p>
    </div>
  );
};

export default CoinFlip;
