import { useState } from "react";
import './styles.css';

const Select = ({ label, value, options, onChange, isDisabled }) => {
  return (
    <>
    <label>
      {label}
    </label>
    <select className="player-dropdown" disabled={isDisabled} value={value} onChange={onChange}>
      {options.map((option) => (
        <option value={option.value} key={option.value}>{option.label}</option>
      ))}
    </select>
    </>
  );
};

function Square({ value, onSquareClick, className }) {
  return (
    <button className={className} onClick={onSquareClick}>
      {value}
    </button>
  );
}

function Board({ nextPlayer, squares, onPlay }) {
  function handleClick(squareNumber) {
    if (squares[squareNumber] == null && calculateWinner(squares) == null) {
      const nextSquares = squares.slice();
      nextSquares[squareNumber] = nextPlayer;
      onPlay(nextSquares);
    }
  }

  const winner = calculateWinner(squares);
  const isBoardFull = isBoardComplete(squares);
  let status;
  if (winner != null) {
    status = "Winner: " + winner;
  } else if (isBoardFull === true) {
    status = "Winner: Tie";
  } else {
    status = "Next move: " + nextPlayer;
  }

  function generateRowSquares(rowNumber) {
    const rowSquares = [];

    for (let columnNumber = 0; columnNumber < 3; columnNumber++) {
      const currentSquare = rowNumber * 3 + columnNumber;
      rowSquares.push(
        <Square
          key={currentSquare}
          className={
            "square square" +
            currentSquare +
            (isSquareWinner(squares, currentSquare) === true ? "Winner" : "")
          }
          value={squares !== undefined && squares !== null ? squares[currentSquare] : null}
          onSquareClick={() => handleClick(currentSquare)}
        />
      );
    }
    return rowSquares;
  }

  return (
    <div>
      <div className="status">{status}</div>
      <div className="board-row">{generateRowSquares(0)}</div>
      <div className="board-row">{generateRowSquares(1)}</div>
      <div className="board-row">{generateRowSquares(2)}</div>
    </div>
  );
}

export default function TicTacToeGame() {
  const player1Options = [
    { label: 'X', value: 'X' },
    { label: '+', value: '+' },
    { label: '#', value: '#' },
    { label: '$', value: '$' },
  ];
    
  const player2Options = [
    { label: 'O', value: 'O' },
    { label: '*', value: '*' },
    { label: '@', value: '@' },
    { label: '%', value: '%' },
  ];
  const [player1, setPlayer1] = useState(player1Options[0].value); 
  const [player2, setPlayer2] = useState(player2Options[0].value);
  const [history, setHistory] = useState([Array(9).fill(null)]);
  const [currentMove, setCurrentMove] = useState(0);
  const player1IsNext = currentMove % 2 === 0;
  const currentSquares = history[currentMove];

  function resetHistory() {
   setHistory([Array(9).fill(null)]);
  }

  function handlePlay(nextSquares) {
    const nextHistory = [...history.slice(0, currentMove + 1), nextSquares];
    setHistory(nextHistory);
    setCurrentMove(nextHistory.length - 1);
  }

  function jumpTo(nextMove) {
    setCurrentMove(nextMove);
  }

  function Player1Selection() { 
    return (
      <div className="player-selection" >
        <Select 
          key="player1"
          label="Player 1: "
          isDisabled={history.length > 1}
          value={player1}
          options={player1Options}
          onChange={(event) => {setPlayer1(event.target.value)}}  />
      </div>
    );
  }

  function Player2Selection() { 
    return (
      <div className="player-selection">
        <Select 
          key="player2"
          label="Player 2: "
          isDisabled={history.length > 2}
          value={player2}
          options={player2Options}
          onChange={(event) => {setPlayer2(event.target.value)}} />
      </div>
    );
  }

  function NavigationDashboard()
  {
    return (
      <nav>
        <button className="nav-button" disabled={currentMove <= 0} onClick={() => jumpTo(currentMove - 1)}>
          <img src="https://correro.net/img/back.png" alt="Back" />
        </button>
        <button className="nav-button" onClick={() => {jumpTo(0); resetHistory()}}>
          <img src="https://correro.net/img/restart.png" alt="Restart" />
        </button>
        <button className="nav-button" disabled={currentMove >= history.length - 1} onClick={() => jumpTo(currentMove + 1)}>
          <img src="https://correro.net/img/forward.png" alt="Forward" />
        </button>
      </nav>
    );
  }

  return (
    <div className="App">
    <h1>Tic Tac Toe</h1>
    <div className="game">
      <div className="game-players">
        <Player1Selection />
        <Player2Selection />
      </div>
      <div className="game-board">
        <Board nextPlayer={player1IsNext ? player1 : player2} squares={currentSquares} onPlay={handlePlay} />
      </div>
      <div className="game-navigation">
        <NavigationDashboard />
      </div>
    </div>
    </div>
  );
}

function calculateWinner(squares) {
  const winningLine = getWinningLine(squares);
  if (winningLine != null) {
    const [a] = winningLine;
    return squares[a];
  }
  return null;
}

function getWinningLine(squares) {
  const winningLines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  if (squares !== undefined && squares !== null)
  {
    for (let i = 0; i < winningLines.length; i++) {
      const [a, b, c] = winningLines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return winningLines[i];
      }
    }
  }
  return null;
}

function isSquareWinner(squares, squareNumber) {
  const winningLine = getWinningLine(squares);
  if (winningLine != null) {
    const [a, b, c] = winningLine;
    if (squareNumber === a || squareNumber === b || squareNumber === c)
      return true;
  }
  return false;
}

function isBoardComplete(squares) {
  let isComplete = true;

  if (squares !== undefined && squares !== null)
  {
    for (let sqrNumber = 0; sqrNumber < squares.length; sqrNumber++) {
      if (squares[sqrNumber] == null) {
        isComplete = false;
        break;
      }
    }
  }
  return isComplete;
}
