import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import SudokuGame from './SudokuGame';
import TicTacToeGame from './TicTacToeGame';
import CoinFlip from './CoinFlip';
import './App.css';

const Home = () => (
  <div>
    <h1>Correro.Net</h1>
    <p>Welcome and feel free to try some React.js games I created!</p>
    <p><Link to="/games">To Games &rArr;</Link></p>
  </div>
);

const Games = () => {
  return (
      <div>
        <h1>Games</h1>
        <ul>
          <li><Link to="coin-flip">Coin Flip</Link></li>
          <li><Link to="sudoku">Sudoku</Link></li>
          <li><Link to="tic-tac-toe">Tic Tac Toe</Link></li>
        </ul>
        <Routes>
          <Route path="coin-flip" element={<CoinFlip />} />
          <Route path="sudoku" element={<SudokuGame />} />
          <Route path="tic-tac-toe" element={<TicTacToeGame />} />
        </Routes>
      </div>
  );
};

// App component with routing
const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/games">Games</Link>
            </li>
          </ul>
        </nav>

        <div className="page-container">
          <Routes>
            <Route path="/games/*" element={<Games />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;

